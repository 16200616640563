<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Content from '@/services/Content'
    import Swal from "sweetalert2";
    import modalAddBusinessEvent from "@/components/modals/business/modalAddBusinessEvent";
    import modalEditBusinessEvent from "@/components/modals/business/modalEditBusinessEvent";
    import modalViewEventInfo from "@/components/modals/business/modalViewEventInfo";


    export default {
        components: { Layout, PageHeader, modalAddBusinessEvent,modalEditBusinessEvent,modalViewEventInfo},
        page: {
            title: "Business Events",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Events Business",
                items: [
                    {
                        text: "News",
                    },
                    {
                        text: "Events Business",
                        active: true,
                        href: "/news/feed",
                    },
                ],
                showRefresh:true,
                showAlert: false,
                isBusyButton: false,
                isBusy: false,
                error: null,
                showModal:false,
                modalData:{},
                link_id:null,
                tableData: [],
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "published",
                sortDesc: true,
                fields: [
                    {
                        key: "title",
                        label: "Event Name",
                        sortable: true,
                    },
                    {
                        key: "image",
                        label: "Image",
                        sortable: false,
                    },
                    {
                        key: "start_at",
                        label: "Date & Time",
                        sortable: true,
                    },
                    {
                        key: "location",
                        label: "Location",
                        sortable: true,
                    },
                    {
                        key: "userLimit",
                        label: "Subscribers 0/max",
                        sortable: true,
                    },
                    {
                        key: "companyLimit",
                        label: "Company Limit",
                        sortable: true,
                    },
                    {
                        key: "registrationLimit",
                        label: "Event Limit",
                        sortable: true,
                    },
                    {
                        key: "toc",
                        label: "Terms and Conditions",
                        sortable: true,
                    },
                    
                    {
                        key: "active",
                        label: "Status",
                        sortable: true,
                    },
                    "action",
                ],
                 "refreshNewsBtn":false
            };
        },
        async created() {
            await this.getEvents()
            this.refreshNewsBtn = await this.checkObjectAccessCall('button.news.refresh');
        },
        methods: {

            modalAddBusinessEvent() {
                this.$bvModal.show("add_business_event");
            },
            callModalBusinessEventEdit(data) {
                this.$bvModal.show("event_edit");
                this.modalData = data;
            },
            callModalViewEventInfo(data) {
                this.$bvModal.show("view_event_info");
                this.modalData = data;
            },

            async getEvents(){
                try {
                    this.toggleBusy();
                    const response = await Content.getBusinessNews(1000,'all','event')
                    this.tableData = response.data.news.map((newsItem) => {
                return {
                    ...newsItem,
                    start_at: newsItem.event_info?.start_at,
                    userLimit: newsItem.event_info?.userLimit,
                    companyLimit: newsItem.event_info?.companyLimit,
                    registrationLimit: newsItem.event_info?.registrationLimit,
                    location: newsItem.event_info?.location,
                    toc: newsItem.event_info?.toc,
                };
            });
                    this.totalRows = this.tableData.length
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRows = 0;
                }
            },

            removeEvent(id) {
    Swal.fire({
        title: "Remove event business?",
        text: "This event business will be removed!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Remove!",
    }).then(async (result) => {
        if (result.value) {
            try {
                const response = await Content.deleteBusinessNews(id);
                if (response.data) {
                    const newsItem = this.tableData.find((item) => item.id === id);
                    if (newsItem) {
                        newsItem.active = 0; 
                    }
                    Swal.fire("Remove Business Event", "Business Event is successfully removed!", "success");
                } else {
                    const error = response.data.error || "Failed";
                    Swal.fire("Fail!", error, "warning");
                }
            } catch (error) {
                this.error = error.response?.data?.error || "An error occurred";
                Swal.fire("Error", this.error, "error");
            }
        }
    });
},

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

        },
        middleware: "authentication",
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                       <div class="row mb-0">
                            <div  class="col-12 text-end">
                                <a href="javascript:void(0);" :disabled=isBusyButton class="btn btn-success waves-effect waves-light text-end" @click="modalAddBusinessEvent()">
                                    <span v-if="!isBusyButton"><i class="mdi mdi-plus me-2"></i>Add Event</span>
                                    <span v-else>Loading...</span>
                                </a>
                            </div>
                        </div>
                        <h4 class="card-title">Total Events: {{totalRows}}</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(image)="data">
                                    <div class="flex-shrink-0">
                                        <img class="img-fluid table-images" :src="data.item.image">
                                    </div>
                                </template>
                                <template v-slot:cell(start_at)="data">
        {{ data.item.start_at }}
    </template>
    <template v-slot:cell(userLimit)="data">
        {{ data.item.userLimit }}
    </template>
<template v-slot:cell(active)="data">
  <span :class="{'text-success': data.item.active === 1, 'text-danger': data.item.active === 0}">
    {{ data.item.active === 1 ? 'Active' : 'Inactive' }}
  </span>
</template>
<template v-slot:cell(action)="data">
  <ul class="list-inline mb-0">
<!--    <li class="list-inline-item" v-if="data.item.active === 1">-->
<!--      <a-->
<!--        href="javascript:void(0);"-->
<!--        class="px-2 text-danger"-->
<!--        v-b-tooltip.hover-->
<!--        title="Remove Business News"-->
<!--        @click="removeEvent(data.item.id)"-->
<!--      >-->
<!--        <i class="uil uil-trash-alt font-size-18"></i>-->
<!--      </a>-->
<!--    </li>-->
    <li class="list-inline-item">
        <a
          href="javascript:void(0);"
          @click="callModalViewEventInfo(data.item)"
          class="px-2 text-primary"
          title="View Event Info"
        >
          <i class="uil uil-eye font-size-18"></i>
        </a>
      </li>
      <li class="list-inline-item">
        <a
          href="javascript:void(0);"
          class="px-2 text-primary"
          v-b-tooltip.hover
          title="Edit Business News"
          @click="callModalBusinessEventEdit(data.item)"
        >
          <i class="uil uil-pen font-size-18"></i>
        </a>
      </li>
  </ul>
</template>
 <template #table-busy>
                <div class="text-center text-danger my-2">
                     <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

   <!-- MODALS -->
      <modalAddBusinessEvent  @refreshScreen="getEvents"></modalAddBusinessEvent>
      <modalEditBusinessEvent :data="modalData"  @onRefresh="getEvents">
      </modalEditBusinessEvent>
      <modalViewEventInfo :data="modalData"></modalViewEventInfo>
      <!-- END  MODALS -->
    </Layout>
</template>