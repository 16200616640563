<script>
import { required, minLength,numeric  } from "vuelidate/lib/validators";
import Content from '@/services/Content';
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import validationMessages from '@/components/validations'


export default {
  components: {
    flatPickr,
    validationMessages,
    ckeditor: CKEditor.component,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
        news_id: "",
        submitted: false,
        showModal: false,
        tryingToEdit: false,
        image_removed: false,
        title: "",
        content: "",
        original_content: "",
        image: "",
        file:null,
        content_type: "event",
        event_info: {
          start_at:null,
          registrationLimit: null,
          userLimit: null,
          companyLimit: null,
          location: '',
          toc: '',
        },
        datetimeconfig: {
          enableTime: true,
          time_24hr: true,
          minuteIncrement: 1,
          inline: true,
          dateFormat: "Y-m-d H:i",
        },
        editor: ClassicEditor,
        csrf_token: localStorage.getItem('csrf_token'),
        verified_checked : false
    };
  },
  validations: {
    title: { required },
    original_content: { required },
    image: { required },
    event_info: {
      start_at: { required },
      registrationLimit: { numeric },
      userLimit: { numeric },
      companyLimit: { numeric },
      location: { required },
      toc: { required },
    },
  },
  methods: {

    async editBusinessEvent() {
      this.tryingToEdit = true;
      this.$v.$touch();

      const now = new Date();
      const selectedDate = new Date(this.event_info.start_at);
      if (!this.event_info.start_at || selectedDate <= now) {
        this.tryingToEdit = false;
        this.failedmsg("Please choose a date in the future");
        return;
      }

      if (this.$v.$invalid) {
        this.tryingToEdit = false;
        //console.error("Validation errors:", this.$v.$errors);
        this.submitted = true;
        return;
      }

      this.csrf_token = localStorage.getItem('csrf_token') || '';
      const formData = new FormData();
      formData.append("title", this.title);
      const modifiedContent = this.convertHtmlToJson(this.content);
      formData.append("content", modifiedContent);
      formData.append("original_content", this.original_content);
      formData.append("original_content", this.original_content);
      formData.append("active", this.verified_checked == true ? 1 : 0);
      formData.append('_method', 'PATCH')

      if (this.file) {
        formData.append("image", this.file);
      }


      formData.append("csrf_token", this.csrf_token);
      formData.append("content_type", this.content_type);

      const eventData = {
        start_at: this.event_info.start_at,
        location: this.event_info.location,
        toc: this.event_info.toc,
        event_limit: Number(this.event_info.registrationLimit),
        user_limit: Number(this.event_info.userLimit),
        company_limit: Number(this.event_info.companyLimit)
      };

      formData.append("event", JSON.stringify(eventData));

      try {
          const response = await Content.editBusinessNews(this.news_id, formData);
          const errorMsg = response.data.error ? response.data.error : 'Update failed';

          if (response.data.data && !response.data.error) {
              this.successmsg("Business Event successfully updated");
              this.closeModal();
              this.refreshData();
          } else {
            //console.error("Update failed:", response.error);
            this.failedmsg(errorMsg);
          }
      } catch (error) {
          this.failedmsg(error.response.data.error);
      } finally {
          this.tryingToEdit = false;
      }
    },

    convertHtmlToJson(htmlContent) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      const articleContent = [];

      doc.body.childNodes.forEach(node => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          articleContent.push({
            type: node.tagName.toLowerCase(),
            text: node.innerText
          });
        }
      });

      return JSON.stringify({
        articleContent: articleContent,
        articleLinks: [],
        articleImages: []
      });
    },
    isFutureDate(date) {
      const now = new Date();
      const selectedDate = new Date(date);
      return selectedDate > now;
    },

    refreshData() {
      this.$emit('onRefresh'); // event from parent
    },

    selectFile(event) {
      if (this.image) {
        URL.revokeObjectURL(this.image);
      }
      const file = event.target.files[0];
      this.file = file;
      this.image = URL.createObjectURL(file);
    },
    removeImage() {
      this.file = null;
      this.image = "";
      // this.image_removed = true; 
      this.$refs["file-input"].reset();
    },

    resetProps() {
      this.submitted = false;
      this.title = "";
      this.content = "";
      this.original_content = "";
      this.image = "";
      this.file = null;
      this.verified_checked = null;
      this.event_info = {
        start_at: '',
        registrationLimit: null,
        userLimit: null,
        companyLimit: null,
        location: '',
        toc: '',
      };
    },

    populateFields() {
        if (this.data) {
            this.news_id = this.data.id || "";
            this.title = this.data.title || "";
            this.original_content = this.data.original_content || "";
            this.content = this.data.content || "";
            this.image = this.data.image || "";
            this.event_info.start_at = this.data.event_info.start_at || "";
            this.event_info.location = this.data.event_info.location || "";
            this.event_info.toc = this.data.event_info.toc || "";
            this.event_info.registrationLimit = this.data.event_info.registrationLimit || "";
            this.event_info.userLimit = this.data.event_info.userLimit || "";
            this.event_info.companyLimit = this.data.event_info.companyLimit || "";
            this.event_info.companyLimit = this.data.event_info.companyLimit || "";
            this.event_info.companyLimit = this.data.event_info.companyLimit || "";
            this.verified_checked = this.data.active || "";
        }
    },

    closeModal() {
      this.showModal = false;
      this.resetProps();
    },

    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Change has been saved",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    failedmsg(msg,title = "Error") {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 5000,
      });
    },
  }
};

</script>

<template>
  <b-modal
      id="event_edit"
      size="lg"
      v-model="showModal"
      :data="data"
      @hidden="resetProps"
      @shown="populateFields"
      title="Edit Business Event"
      title-class="font-18"
  >
    <form @submit.prevent="editBusinessEvent">
      <div class="row">
        <div class="col-md-6">

          <b-form-checkbox-group>
            <div class="form-check form-switch form-switch-lg mb-3" >
              <label class="form-check-label" for="newsStatus">Active</label>
              <input v-model="verified_checked"
                     type="checkbox"
                     class="form-check-input"
                     id="newsStatus"
              />
            </div>
          </b-form-checkbox-group>

          <b-form-group label="Event Name" label-for="title" class="mb-3">
            <b-form-input
                v-model="title"
                id="title"
                :class="{ 'is-invalid': submitted && $v.title.$error }"
            ></b-form-input>
            <validationMessages
                v-if="submitted"
                :fieldName="'Event Name'"
                :validationName="$v.title"
            ></validationMessages>
          </b-form-group>

          <b-form-group label="Date and Time" label-for="start_at" class="mb-3">
            <flat-pickr
                v-model="event_info.start_at"
                :config="datetimeconfig"
                class="form-control mb-2"
                :class="{ 'is-invalid': submitted && !isFutureDate(event_info.start_at) }"
                name="start_at"
            />
            <validationMessages
                v-if="submitted"
                :fieldName="'Date and Time'"
                :validationName="$v.event_info.start_at"
                :customError="!isFutureDate(event_info.start_at) ? 'Please choose a date in the future' : ''"
            ></validationMessages>
          </b-form-group>

        </div>
        <div class="col-md-6">

          <b-form-group label="Upload Image" label-for="image" class="mb-3 image-area">
            <img
                :src="image || require('@/assets/images/image_not_found.png')"
                alt=""
                width="150"
                class="img-thumbnail me-2"
            />
            <b-form-file
                @change="selectFile"
                ref="file-input"
                type="file"
                class="mt-3"
                plain
                accept="image/jpeg, image/png"
            ></b-form-file>
            <a
                v-if="image"
                class="remove-image"
                href="javascript:void(0);"
                @click="removeImage"
                style="display: inline;"
            >
              &#215;
            </a>
            <validationMessages
                v-if="submitted"
                :fieldName="'Image'"
                :validationName="$v.image"
            ></validationMessages>
          </b-form-group>
          <b-form-group label="Location" label-for="location" class="mb-3">
            <b-form-input
                v-model="event_info.location"
                id="title"
                :class="{ 'is-invalid': submitted && $v.event_info.location.$error }"
            ></b-form-input>
            <validationMessages
                v-if="submitted"
                :fieldName="'Location'"
                :validationName="$v.event_info.location"
            ></validationMessages>
          </b-form-group>
          <b-form-group label="Maximum Event Subscriptions Limit" label-for="event_limit" class="mb-3">
            <b-form-input
                v-model="event_info.registrationLimit"
                id="event_limit"
                :class="{ 'is-invalid': submitted && $v.event_info.registrationLimit.$error }"
            ></b-form-input>
            <validationMessages
                v-if="submitted"
                :fieldName="'Event Limit'"
                :validationName="$v.event_info.registrationLimit"
            ></validationMessages>
          </b-form-group>

          <b-form-group label="Maximum Subscriptions Per User Limit" label-for="user_limit" class="mb-3">
            <b-form-input
                v-model="event_info.userLimit"
                id="user_limit"
                :class="{ 'is-invalid': submitted && $v.event_info.userLimit.$error }"
            ></b-form-input>
            <validationMessages
                v-if="submitted"
                :fieldName="'User Limit'"
                :validationName="$v.event_info.userLimit"
            ></validationMessages>
          </b-form-group>
          <b-form-group label="Maximum Subscriptions Per Company Limit" label-for="company_limit" class="mb-3">
            <b-form-input
                v-model="event_info.companyLimit"
                id="company_limit"
                :class="{ 'is-invalid': submitted && $v.event_info.companyLimit.$error }"
            ></b-form-input>
            <validationMessages
                v-if="submitted"
                :fieldName="'Company Limit'"
                :validationName="$v.event_info.companyLimit"
            ></validationMessages>
          </b-form-group>
          <b-form-group label="Terms and Conditions" label-for="toc" class="mb-3">
            <b-form-input
                v-model="event_info.toc"
                id="toc"
                :class="{ 'is-invalid': submitted && $v.event_info.toc.$error }"
            ></b-form-input>
            <validationMessages
                v-if="submitted"
                :fieldName="'Terms and Conditions'"
                :validationName="$v.event_info.toc"
            ></validationMessages>
          </b-form-group>

        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <b-form-group label="Content" label-for="content" class="mb-3">
            <ckeditor :editor="editor" v-model="original_content"></ckeditor>
            <validationMessages
                v-if="submitted"
                :fieldName="'Content'"
                :validationName="$v.original_content"
            ></validationMessages>
          </b-form-group>
        </div>
      </div>


      <input type="hidden" name="content_type" v-model="content_type" />
      <input type="hidden" name="csrf_token" v-model="csrf_token" />
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="editBusinessEvent" :disabled="tryingToEdit">
        <b-spinner v-show="tryingToEdit" small></b-spinner>
        Submit
      </b-button>
    </template>
  </b-modal>
</template>